import React from "react"
import ImageGallery from "react-image-gallery"

const images = [
  { original: "./casas/CTC_1637.jpg", thumbnail: "./casas/CTC_1637.jpg" },
  { original: "./casas/CTC_1638.jpg", thumbnail: "./casas/CTC_1638.jpg" },
  { original: "./casas/CTC_1643.jpg", thumbnail: "./casas/CTC_1643.jpg" },
  { original: "./casas/CTC_1645.jpg", thumbnail: "./casas/CTC_1645.jpg" },
  { original: "./casas/CTC_1646.jpg", thumbnail: "./casas/CTC_1646.jpg" },
  { original: "./casas/CTC_1647.jpg", thumbnail: "./casas/CTC_1647.jpg" },
  { original: "./casas/CTC_1655.jpg", thumbnail: "./casas/CTC_1655.jpg" },
  { original: "./casas/CTC_1670.jpg", thumbnail: "./casas/CTC_1670.jpg" },
  { original: "./casas/CTC_1678.jpg", thumbnail: "./casas/CTC_1678.jpg" },
]

// import { Container } from './styles';

export default function ModelosCasas() {
  return (
    <section class="fdb-block bg-dark" id="modelo-casa">
      <div className="container text-center mb-3">
        <div className="row">
          <div className="col-12">
            <h2 className="h1">Conheça nosso trabalho</h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ImageGallery
              // renderLeftNav={() => null}
              // renderRightNav={() => null}
              renderPlayPauseButton={() => null}
              renderFullscreenButton={() => null}
              items={images}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

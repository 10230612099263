import React from "react"

const diferenciais = [
  {
    titulo: "Ótimas opções de localização",
    texto:
      "A Revo Brasil é imobiliária credenciada da Setpar e tem exclusividade de vendas dos terrenos em diversas localizações em São José do Rio Preto",
    icon: "./pin.svg",
  },
  {
    titulo: "Personalização da planta",
    texto:
      " Você escolhe como o projeto vai ficar de acordo com o que você precisa",
    icon: "./project.svg",
  },
  {
    titulo: "Personalização do acabamento",
    texto: "",
    icon: "./paint.svg",
  },
  {
    titulo: "FGTS",
    texto: "É possível utilizar o FGTS como parte do pagamento",
    icon: "./fgts.png",
  },
  {
    titulo: "Minha Casa, Minha Vida",
    texto:
      "Você tem a possibilidade de conseguir os melhores juros e benefícios do programa",
    icon: "./minha-casa-minha-vida.png",
  },
  {
    titulo: "Facilidade",
    texto:
      "Você pode parcelar a entrada e documentação do seu terreno com valores que você pode pagar",
    icon: "./piggy-bank.svg",
  },
]

export default function Diferenciais() {
  return (
    <section class="fdb-block bg-light">
      <div class="container">
        <div class="row text-center">
          <div class="col-12 col-md-8 mx-auto">
            <h2>
              Por que a Revo Brasil vai me ajudar a construir minha casa do
              melhor jeito?
            </h2>
          </div>
        </div>
        <div class="row text-left mt-5">
          {diferenciais.map((item, index) => (
            <div class="col-12 col-md-4 pt-3 pt-sm-4 pt-md-0" key={index}>
              <div class="row">
                <div class="col-3">
                  <img alt={item.titulo} class="fdb-icon" src={item.icon} />
                </div>
                <div class="col-9">
                  <h3>
                    <strong>{item.titulo}</strong>
                  </h3>
                  <p>{item.texto}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

import React from "react"
import CurrencyInput from "react-currency-input"

export default function MoneyField({ field, form, ...props }) {
  return (
    <CurrencyInput
      {...field}
      {...props}
      decimalSeparator=","
      thousandSeparator="."
      prefix="R$ "
      onChangeEvent={(event, maskedvalue, floatvalue) =>
        form.setFieldValue(field.name, floatvalue)
      }
    />
  )
}

import React from "react"

// import { Container } from './styles';

export default function ComoFunciona() {
  return (
    <section className="fdb-block text-center" id="comofunciona">
      <h2 className="mb-5 h1">Como funciona</h2>
      <img
        src="/timeline_horizontal.png"
        alt="Como funciona"
        className="img-fluid d-none d-md-block m-auto"
      />
      <img
        src="/timeline_vertical.png"
        alt="Como funciona"
        className="img-fluid d-md-none m-auto"
      />
    </section>
  )
}
